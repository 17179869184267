<template>
  <v-container>
     <!-- BOC:[breadcrumbs] -->
     <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <AError
      v-if="api.isError"
      :api="api"
      :callbackReset="() => (api.isError = false)"
    ></AError>
    <v-card
      class="mx-auto pa-3"
      max-width="800"
      outlined
      min-height="200"
    >
      <v-row>
        <v-col><h2>{{ institution.institutionName }}</h2></v-col>
      </v-row>
      <v-card-title></v-card-title>
      <ALoader :isLoading="api.isLoading"></ALoader>
      <Read v-if="!api.isLoading" :headers="headers" :data="institution" />

      <div v-if="institution.status == 'pending'" class="d-flex align-center justify-space-around">
        <div class="form-content-pending text-center">
          <div class="text-center pt-1">
            <v-btn width="75%" :to="{name:'PageEducatorInstitutionApplicationEdit',params:{id:this.$route.params.id}}" rounded color="primary" dark>
              Edit Application
            </v-btn>
          </div>
          <div class="text-center pt-2">
            <v-btn width="75%" :to="{name:'PageEducatorInstitutionApplicationCancel',params:{id:this.$route.params.id}}"  rounded outlined color="primary" dark>
              Cancel Application
            </v-btn>
          </div>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
     import { mapState } from "vuex";
     import Read from "../../components/Datatable/Read.vue";
export default {
  components: {
    Read,
  },
  computed: {
    size () {
      const size = {xs:'small',sm:'small',lg:'large',xl:'x-large'}[this.$vuetify.breakpoint.name];
      return size ? { [size]: true } : {}
    },
    ...mapState({
      auth: (state) => state.auth.data
    })
  },
  // computed: mapState({
  //     auth: (state) => state.auth.data,
  //   }),
  props: [
    //
  ],
  data: () => ({
       //BOC:[breadcrumbs]
       breadcrumbs: [],
    //EOC
   
    headers: [
      { text: "Date of application", value: "timestampCreated" },
      { text: "Name", value: "institutionName" },
      { text: "Mobile Number", value: "applicantMobile" },
      { text: "Email Address", value: "applicantEmail" },
    ],
    data: {},
    institution:{},
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  created() {
     this.api.method = "GET";
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/application/" +
      this.$route.params.id;

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.institution = resp;
      this.updateBreadcrumb(resp);
    };
    //EOC
    
  },
  mounted() {
    this.$api.fetch(this.api);
  },
  methods: {
    updateBreadcrumb(resp) {
      this.breadcrumbs.push({
        text: "Dashboard",
        to: { name: "PageEducatorDashboard" },
        exact: true,
      });
      this.breadcrumbs.push({
        text: resp.institutionName,
        to: {
          name: "PageEducatorInstitutionApplicationRead",
          params: { id: this.$route.params.id },
        },
        exact: true,
      });
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 600px !important;
}
.form-content-pending {
  width: 300px !important;
}
</style>